import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import "../styles/animcards.css";
import GroupedAnimCardsContainer from '@/components/AnimCardsGroup';

export default function SponsScrollBar() {

    const [setIsPlaying] = useState(false);

    const data = useStaticQuery(graphql`
      query {
        allFile(filter: {sourceInstanceName: {eq: "spons"}}) {
          edges {
            node {
              base
              relativeDirectory
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 70, quality: 80, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    `);
  
    // Group images by folder name
    const groupedData = data.allFile.edges.reduce((acc, edge) => {
      const folderName = edge.node.relativeDirectory;
      if (!acc[folderName]) {
        acc[folderName] = [];
      }
      acc[folderName].push({
        imgData: edge.node.childImageSharp.gatsbyImageData,
        title: edge.node.base.split('.')[0]
      });
      return acc;
    }, {});
  
    const sponsGroups = Object.keys(groupedData).map(folderName => ({
      label: folderName,
      data: groupedData[folderName]
    }));

    return (
    <section>
        <GroupedAnimCardsContainer groups={sponsGroups} title="Proud Partners of the ARC APAC Summit 2025" />
    </section>
    );
}