import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { GatsbyImage } from 'gatsby-plugin-image';
import sponsors from '../data/spons';

const Card = ({ imgData, title, link }) => (
  <div className="card-v2 rounded">
    <figure>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <GatsbyImage image={imgData} alt={title} />
      </a>
    </figure>
  </div>
);

const AnimCards = ({ groups, paused }) => {
  const listRef = useRef(null);
  const [translate, setTranslate] = useState(0);
  const animationId = useRef(null);
  const { ref, inView } = useInView({ threshold: 0.1 });

  const animate = () => {
    if (paused) {
      cancelAnimationFrame(animationId.current);
      return;
    }

    const delta = 0.5; // Adjust the speed as needed
    const cardWidth = getCardWidth();
    const totalWidth = cardWidth * groups.reduce((acc, group) => acc + group.data.length, 0) * 3; // Total width for 3 sets
    const newTranslate = translate + delta;

    if (newTranslate >= totalWidth - window.innerWidth) {
      setTranslate(0);
    } else {
      setTranslate(newTranslate);
    }

    if (listRef.current) {
      listRef.current.style.transform = `translateX(${-translate}px)`;
    }

    animationId.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (inView && !paused) {
      animationId.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(animationId.current);
    }
    return () => cancelAnimationFrame(animationId.current);
  }, [inView, paused, translate]);

  const getCardWidth = () => {
    if (listRef.current && listRef.current.children.length > 0) {
      const card = listRef.current.children[0];
      return card.offsetWidth + parseFloat(window.getComputedStyle(card).marginRight);
    }
    return 0;
  };

  const getSponsorLink = (title) => {
    const sponsor = sponsors.find(sponsor => sponsor.name.toLowerCase() === title.toLowerCase());
    return sponsor ? sponsor.website : '#';
  };

  const sortSponsors = (a, b) => {
    const sponsorA = sponsors.find(sponsor => sponsor.name.toLowerCase() === a.title.toLowerCase());
    const sponsorB = sponsors.find(sponsor => sponsor.name.toLowerCase() === b.title.toLowerCase());

    if (sponsorA?.order !== undefined && sponsorB?.order !== undefined) {
      return sponsorA.order - sponsorB.order;
    }
    if (sponsorA?.order !== undefined) {
      return -1;
    }
    if (sponsorB?.order !== undefined) {
      return 1;
    }
    return a.title.localeCompare(b.title);
  };

  const sortedGroups = groups.map(group => ({
    ...group,
    data: group.data.sort(sortSponsors),
  }));

  const duplicatedData = Array(3).fill(sortedGroups).flat();

  const transformLabel = (label) => {
    const words = label.split('-');
    return words[0].charAt(0).toUpperCase() + words[0].slice(1) + ' ' + words.slice(1).join(' ');
  };

  return (
    <div className="anim-cards js-anim-cards" ref={ref}>
      <ul className="anim-cards__list" ref={listRef}>
        {duplicatedData.map((group, groupIndex) =>
          group.data.map((item, itemIndex) => (
            <li
              key={`${groupIndex}-${itemIndex}`}
              className={`anim-cards__logo group__item ${itemIndex === 0 ? 'spons-group' : ''}`}
            >
              {itemIndex === 0 && <div className="group__label">{transformLabel(group.label)}</div>}
              <Card imgData={item.imgData} title={item.title} link={getSponsorLink(item.title)} />
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

const GroupedAnimCardsContainer = ({ groups, title }) => {
  const [paused, setPaused] = useState(false);

  const handlePausePlay = () => {
    setPaused(!paused);
  };

  const categoryOrder = [
    'state-government-partner',
    'diamond-partner',
    'platinum-partner',
    'gold-partner',
    'silver-partner',
    'regional-collaborator',
  ];

  const orderedGroups = categoryOrder
    .map(category => groups.find(group => group.label.toLowerCase().replace(/ /g, '-') === category))
    .filter(group => group !== undefined);

  return (
    <>
      {/* play/pause button hidden 6 Dec 2024 */}
      {/* <div className="container mx-auto text-center">
        <div className="grid grid-cols-1 lg:grid-cols-3 justify-between items-center gap-3">
          <div></div>
          <p className="text-2xl mb-0 text-[#10044a]">{title}</p>
          <div className="text-center lg:text-end">
            <span className="me-2 text-[#10044a]">Play / Pause slide</span>
            <button
              className="anim-cards-control js-anim-cards-control js-tab-focus"
              aria-label={paused ? 'play animation' : 'pause animation'}
              aria-pressed={paused}
              onClick={handlePausePlay}
            >
              <i className="not-italic"></i>
            </button>
          </div>
        </div>
      </div> */}
     
      <div className="container mx-auto text-center">
        <p className="lg:text-2xl mb-0 text-[#10044a]">{title}</p>
      </div>

      <div id="animated-cards">
        <AnimCards groups={orderedGroups} paused={paused} />
      </div>
    </>
  );
};

export default GroupedAnimCardsContainer;
