import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars2Icon
} from "@heroicons/react/24/solid";
import GalleryButton from "@/components/GalleryBtn"

// Dropdown list of About
const AboutSub = [
  {
    title: "Promise of the ARC APAC Summit",
    to: "/promise"
  },
  {
    title: "Host: ARC Innovation",
    to: "/host"
  },
  {
    title: "Committee",
    to: "/committee"
  },
  {
    title: "Latest News",
    to: "/latest-news"
  },
];

const ProgramSub = [
  {
    title: "Program",
    to: "/program"
  },
  {
    title: "Themes",
    to: "/themes"
  },
  {
    title: "Speakers",
    to: "/speakers"
  },
];

// Dropdown list of Destination
const DestinationSub = [
  {
    title: "Destination",
    to: "/destination"
  },
  {
    title: "Venue",
    to: "/venue"
  },
  {
    title: "Visa Information",
    to: "/visa-information"
  },
];

// Dropdown list of Partnerships
const PartnershipsSub = [
  {
    title: "Partnerships",
    to: "/partnerships"
  },
  {
    title: "Partners",
    to: "/partners"
  },
];

// Dropdown Component of About
function AboutList() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
  const renderItems = AboutSub.map(({ title, to }) => (
    <a href={to} key={title}>
      <MenuItem className="px-0 focus:outline-none">
        <Typography variant="p" className="mb-1 text-start text-white hover:text-hyelo-400 text-sm">
          {title}
        </Typography>
      </MenuItem>
    </a>
  ));
 
  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography variant="small" className="font-normal mb-0 hidden lg:block">
            <MenuItem className="hidden items-center gap-2 text-white hover:text-hyelo-400 lg:flex lg:rounded-full">
              About{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-48 grid-cols-1 p-3 gap-3 bg-hturq-800 overflow-visible lg:grid">
          <ul className="flex w-full flex-col gap-1 text-sm focus:outline-none">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-sm text-white lg:hidden">
        About{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden text-sm !text-white focus:outline-none">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

function ProgramList() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
  const renderItems = ProgramSub.map(({ title, to }) => (
    <a href={to} key={title}>
      <MenuItem className="px-0 focus:outline-none">
        <Typography variant="p" className="mb-1 text-start text-white hover:text-hyelo-400 text-sm">
          {title}
        </Typography>
      </MenuItem>
    </a>
  ));
 
  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography variant="small" className="font-normal mb-0 hidden lg:block">
            <MenuItem className="hidden items-center gap-2 text-white hover:text-hyelo-400 lg:flex lg:rounded-full">
              Program{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-48 grid-cols-1 p-3 gap-3 bg-hturq-800 overflow-visible lg:grid">
          <ul className="flex w-full flex-col gap-1 text-sm focus:outline-none">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-sm text-white lg:hidden">
        Program{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden text-sm !text-white focus:outline-none">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

// Dropdown Component of Destination
function DestinationList() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
  const renderItems = DestinationSub.map(({ title, to }) => (
    <a href={to} key={title}>
      <MenuItem className="px-0 focus:outline-none">
        <Typography variant="p" className="mb-1 text-start text-white hover:text-hyelo-400 text-sm">
          {title}
        </Typography>
      </MenuItem>
    </a>
  ));
 
  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography variant="small" className="font-normal mb-0 hidden lg:block">
            <MenuItem className="hidden items-center gap-2 text-white hover:text-hyelo-400 lg:flex lg:rounded-full">
              Destination{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-48 grid-cols-1 p-3 gap-3 bg-hturq-800 overflow-visible lg:grid">
          <ul className="col-span-4 flex w-full flex-col gap-1 focus:outline-none">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-sm text-white lg:hidden">
      Destination{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden text-sm focus:outline-none">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

// Dropdown Component of Partnerships
function PartnershipsList() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
  const renderItems = PartnershipsSub.map(({ title, to }) => (
    <a href={to} key={title}>
      <MenuItem className="px-0 focus:outline-none">
        <Typography variant="p" className="mb-1 text-start text-white hover:text-hyelo-400 text-sm">
          {title}
        </Typography>
      </MenuItem>
    </a>
  ));
 
  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography variant="small" className="font-normal mb-0 hidden lg:block">
            <MenuItem className="hidden items-center gap-2 text-white hover:text-hyelo-400 lg:flex lg:rounded-full">
            Partnerships{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-48 grid-cols-1 p-3 gap-3 bg-hturq-800 overflow-visible lg:grid">
          <ul className="col-span-4 flex w-full flex-col gap-1 focus:outline-none">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-sm text-white lg:hidden">
      Destination{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden text-sm focus:outline-none">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

function NavList() {
  return (
    <ul className="flex flex-col lg:flex-row flex-wrap gap-2 lg:items-center">
      <div className="lg:hidden inline">
        <GalleryButton displayText="View ARC APAC Summit 2025 Photo Gallery" />
      </div>
      <Typography
          key="home"
          as="a"
          href="/"
          variant="small"
          color="gray"
          className="font-light"
        >
        <MenuItem className="flex items-center lg:rounded-full">
          <span className="text-white hover:text-hyelo-400">Home</span>
        </MenuItem>
      </Typography>
      <AboutList />
      <Typography
        as="a"
        href="/registration"
        variant="small"
        color="gray"
        className=""
      >
        <MenuItem className="flex items-center gap-2 lg:rounded-full">
          <span className="text-white hover:text-hyelo-400">Registration</span>
        </MenuItem>
      </Typography>
      <Typography
        as="a"
        href="/accommodation"
        variant="small"
        color="gray"
        className=""
      >
        <MenuItem className="flex items-center gap-2 lg:rounded-full">
          <span className="text-white hover:text-hyelo-400">Accommodation</span>
        </MenuItem>
      </Typography>
      <ProgramList />
      <DestinationList />
      <PartnershipsList />
      <Typography
        as="a"
        href="/contact"
        variant="small"
        color="gray"
        className=""
      >
        <MenuItem className="flex items-center gap-2 lg:rounded-full">
          <span className="text-white hover:text-hyelo-400">Contact Us</span>
        </MenuItem>
      </Typography>
      <div className="hidden lg:inline-block">
        <GalleryButton displayText="Photo Gallery" />
      </div>
    </ul>
  );
}
 
export function ComplexNavbar() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
 
  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false),
    );
  }, []);
 
  return (
    <Navbar className="p-3 bg-transparent border-0 shadow-none">
      <div className="relative flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          className="cursor-pointer py-1.5 text-white"
        >
          <img src="/logo/arcapac25_rev.png" className="w-full max-h-[80px]" />
        </Typography>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={() => setIsNavOpen(!isNavOpen)}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>
      </div>
      {isNavOpen && (
        <Collapse open={isNavOpen} className="overflow-hidden p-3 bg-hturq-800 border border-white rounded-lg">
          <NavList />
        </Collapse>
      )}
    </Navbar>
  );
}

export function ComplexNavbarHome() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false),
    );
  }, []);
 
  return (
    <Navbar className="relative p-3 bg-transparent border-0 shadow-none !max-w-full">
      <a href="https://arcinnovation.org/" target="_blank" rel="noopener noreferrer" className="flex items-center max-lg:hidden">
          <img src="/images/ARC-logo.png" alt="ARC Logo" width="180" className="max-h-[80px] absolute top-2 left-[5%]" />
      </a>
      <div className="w-full flex items-center justify-end flex-wrap text-blue-gray-900">
        <a href="https://arcinnovation.org/" target="_blank" rel="noopener noreferrer" className="flex items-center lg:hidden">
          <img src="/images/ARC-logo.png" alt="ARC Logo" width="180" className="max-h-[80px]" />
        </a>
        <div className="hidden lg:block max-xl:max-w-2xl">
          <NavList />
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={() => setIsNavOpen(!isNavOpen)}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>
      </div>
      {isNavOpen && (
        <Collapse open={isNavOpen} className="overflow-hidden p-3 bg-hturq-800 border border-white rounded-lg mobileNav">
          <NavList />
        </Collapse>
      )}
    </Navbar>
  );
}