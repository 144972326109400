import React from 'react';
import { Button } from "@/components/ui/button"

const GalleryButton = ({ displayText }) => {
  return (
    <div className="relative inline-block">
      <a href="https://lorettagodfreyphotography.pixieset.com/arcapacsummitmelbourne2025/" target="_blank" rel="noreferrer noopener">
        <Button className="relative">{displayText}</Button>
      </a>
      <div className="absolute -top-1 -right-1">
        <span className="relative flex size-3">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-600 opacity-75"></span>
          <span className="relative inline-flex size-3 rounded-full bg-red-600"></span>
        </span>
      </div>
    </div>
  );
};

export default GalleryButton;