import React from "react";
import { Link } from "gatsby";
import { menuItems } from "./navbar/menuItems";
import UserForm from '@/components/form/EOI-Form';
import countries from "../data/countries";
import Socials from './Socials';

const FooterLinkSection = ({ title, links }) => (
  <div>
    <h4 className="text-lg">{title}</h4>
    <ul className="text-gray-400">
      {links.map((link, index) => (
        <li key={index} className="mb-4">
          <Link to={link.to} className="text-white hover:text-gray-200">{link.name}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default function Footer() {

  const eventName = 'ARC APAC 2025'; // Enter the event name (Once set, do not change!)
  const eventDate = '2025-03-12'; // Dynamically set event date in YYYY-MM-DD format

  return (
    <footer className="w-full bg-hturq-900 text-white py-12 md:py-16">
      <section>
        <div className="w-full">
          <div className="flex items-center justify-center w-full bg-hturq-900">
            <div className="flex lg:flex-row flex-col items-center justify-center w-full container p-8 rounded-lg bg-hturq-900">
              <div className="lg:basis-1/2 lg:pr-12 lg:border-r lg:border-gray-700">
                <h1 className="mb-4 text-white" id="subscribe">Subscribe to our Newsletter</h1>
                <UserForm eventName={eventName} eventDate={eventDate} countries={countries} />
              </div>
              <div className="lg:basis-1/2 lg:pl-12 mt-12 lg:mt-0 w-full">
                <Socials />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto px-4 md:px-6 py-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {menuItems.footer.map((section, index) => (
          <FooterLinkSection key={index} title={section.title} links={section.links} />
        ))}
      </section>
      <div className="container mx-auto px-4 md:px-6 mt-8  flex lg:flex-row flex-col justify-center">
        <div className=""><p className="text-gray-300 mb-0">© ARC APAC Summit 2025. All Rights Reserved</p></div>
      </div>
    </footer>
  );
}
